.main-nav {
  background: $primary-color;
  height: 60px;
  position: relative;
  @media #{$normal} {
    background: $black;
    height: 50px;
    border-top: 3px solid $primary-color;
    box-sizing: content-box;
  }
  .menu-btn {
    display: inline-block;
    @media #{$normal} {
      display: none;
    }
  }
  ul.menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    @media #{$normal} {
      display: block;
      position: static;
    }
  }
}

.main-nav {
  .menu-btn {
    background-color: transparent;
    border: none;
    color: $white;
    text-decoration: none;
    font-size: 1.5em;
    line-height: 60px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    outline: none;
    &__burger {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 25px;
      vertical-align: middle;
      margin-right: 5px;
      span,
      span::before,
      span::after {
        cursor: pointer;
        height: 4px;
        width: 25px;
        background-color: $white;
        position: absolute;
        display: block;
        content: '';
        transition: all .3s ease;
      }
      span {
        top: 8px;
        &::before {
          top: -8px;
        }
        &::after {
          bottom: -8px;
        }
      }
    }
    &.open {
      .menu-btn__burger {
        span {
          background-color: transparent;
          &::before {
            transform: rotate(45deg);
            top: 0;
          }
          &::after {
            transform: rotate(-45deg);
            bottom: 0;
          }
        }
      }
    }
    &__label {
      display: inline-block;
    }
  }
  ul.menu {
    text-transform: uppercase;
    margin: 0;
    padding: 15px;
    z-index: 999;
    background: $black;
    li {
      padding: 10px 0;
      line-height: 1.1em;
      &.expanded {
        padding-bottom: 0;
      }
    }
    a {
      display: block;
      text-decoration: none;
      color: $white;
    }
    ul {
      padding: 10px 0 0 10px;
      z-index: 999;
      a {
        border-left: 5px solid $white;
        padding-left: 5px;
      }
    }
  }
}

@media #{$normal} {
  .main-nav {
    ul.menu {
      padding: 0;
      li {
        position: relative;
        display: inline-block;
        float: left;
        margin: 0 15px 0 0;
        padding: 0;
        transition: all .5s ease-out;
        height: 50px;
        &:hover {
          background: $primary-color;
          color: $white;
          & > ul {
            display: block;
          }
        }
      }
      a {
        line-height: 1em;
        padding: 0 15px 0 5px;
        margin: 15px 0 0 0;
        position: relative;
        border-left: 10px solid $primary-color;
      }
      ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 0;
        width: 200%;
        background: $black;
        li {
          width: 100%;
          height: 40px;
        }
        a {
          padding: 12px 15px;
          text-align: left;
          border-width: 0;
          margin: 0;
        }
      }
    }
  }
}
