body.front {
  background: #282828 url("../images/front-bg.jpg") no-repeat left center;
}

.front {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 300px;
  @media #{$normal} {
    display: block;
  }
  .main-nav--front {
    order: 2;
    border-top: none;
    background: transparent;
    @media #{$normal} {
      margin-top: 85px;
    }
    .container {
      background-color: $primary-color;
      @media #{$normal} {
        background-color: transparent;
      }
    }
  }
  &__body {
    order: 1;
    margin-top: 30px;
    @media #{$normal} {
      margin-top: 200px;
    }
    @media screen and (max-width: #{$screen-md-min}) {
      .container {
        padding: 0;
      }
    }
  }
  &__buttons {
    margin: 0;
    padding: 0;
    list-style: none;
    @media #{$normal} {
      border-bottom: 3px solid $primary-color;
      padding-bottom: 40px;
    }
  }
  &__button {
    color: $white;
    padding-left: 45px;
    padding-right: 15px;
    margin-bottom: 20px;
    font-size: 1.5em;
    line-height: 60px;
    text-transform: uppercase;
    background: $primary-color url("../images/arrow-right-wht.svg") no-repeat 15px 50%;
    @media #{$normal} {
      max-width: 310px;
      background: transparent url("../images/arrow-right-red.svg") no-repeat 15px 50%;
      border: 1px solid $white;
      margin-bottom: 40px;
      transition: all .3s ease;
      &:hover {
        background-color: darken($primary-color, 15%);
      }
    }
    a {
      color: inherit;
      text-decoration: none;
      display: block;
    }
  }
  &__logo {
    order: 0;
    margin-top: 30px;
    @media #{$normal} {
      margin-top: 50px;
    }
    img {
      width: 255px;
      margin: 0 auto;
      display: block;
      @media #{$normal} {
        width: 310px;
        margin: 0;
      }
    }
  }
  &__tagline {
    order: 3;
    margin: 100px 0;
    text-align: center;
    font-weight: 300;
    font-size: 2.875em;
    line-height: 1.2em;
    @media #{$normal} {
      margin: -65px 0 30px 0;
      text-align: left;
      font-size: 1.875em;
    }
    p {
      margin: 0;
      @media #{$normal} {
        padding-left: 380px;
      }
    }
  }
}
