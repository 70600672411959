/**
* Typography: Headings, body text, lists, code, and more for a versatile and durable typography system.
*/

body {
  font-family: $font-body;
  font-size: $base-font-size;
  line-height: $base-line-height;
  background: #282828;
  color: $white;
}

// Headings

h1, h2, h3, h4, h5, h6 {
  font-family: $open-sans;
  color: $white;
  font-weight: bold;
  a:link {
    text-decoration: none;
  }
}

h1 {
  line-height: 1.1em;
  font-size: 2.25em;
  margin-bottom: 5px;
  &.page-title {
    margin-top: 0;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
}

// Links

a {

  &:link {
    color: $link;
    text-decoration: underline;
  }

  &:visited {
    color: $visited;
  }

  &:hover, &:focus {
    color: $white;
    text-decoration: none;
  }

  &:active, &.active {
    text-decoration: none;
  }
}

// Lists

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

// Misc.

hr {
  height: 1px;
  border: 1px solid #666;
  padding-bottom: -1px;
  margin: 1em 0;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

// Remove text-shadow in selection highlight: h5bp.com/i
// These selection declarations have to be separate
::-moz-selection { background: $selection-bg; color: $selection; text-shadow: none; }
::selection { background: $selection-bg; color: $selection; text-shadow: none; }
