//----------------------------------*\
// VARS
//----------------------------------*/

//----------------------------------*\
// STYLES
//----------------------------------*/
/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */
audio,
canvas,
img,
video {
	vertical-align: middle;
}

/**
 * Images
 */
img {
	max-width: 100%;
	height: auto;
}

/**
 * Responsive Embeds
 */
.embed-responsive {
	overflow: hidden;
	position: relative;
	display: block;
	width: 100%;
	height: 0;
	padding: 0;

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}

	&--ar16x9 {
		padding-top: 56.25%;
	}

	&--ar4x3 {
		padding-top: 75%;
	}
}
