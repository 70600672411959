.footer {
  padding: 40px 0;
  margin: 0;
  border-width: 0;
  background-color: $black;
  font-size: .875em;
  text-align: center;
  border-top: 3px solid $primary-color;
  @media #{$normal} {
    border-top-width: 0;
  }
  @media #{$tablet} {
    padding: 60px 0;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    display: block;
    margin-bottom: 5px;
    @media #{$normal} {
      display: inline;
      &:not(:last-child)::after {
        content: "|";
      }
    }
  }
  &.footer--front {
    @media #{$normal} {
      background-color: transparent;
    }
  }
}
