/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `!default` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./THEMENAME/bootstrap/assets/stylesheets/bootstrap/_variables.scss
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../bootstrap/assets/fonts/bootstrap/';

$grid-gutter-width: 30px;

// Large screen / wide desktop
$container-large-desktop: (1110px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;
