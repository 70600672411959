
////////////////////////
//
// Font variables.
//
////////////////////////

$base-font-size:   16px;
$base-line-height: 24px;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica:       Helvetica, Arial, "Nimbus Sans L", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;

// Project-specific fonts
// Put any font families here that are specific to your project.
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i);

$open-sans: 'Open Sans', sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $georgia; // The font family set on the html element.
$font-body:        $open-sans;
$font-monospace:   $courier;
$form-font-family: $arial;
