////////////////////////
//
// Color variables.
//
////////////////////////

$primary-color: #990000;

// monochrome colors
$black: #000;
$gray-darker:  lighten($black, 13.5%) !default; // #222
$gray-dark:    lighten($black, 20.0%) !default; // #333
$gray:         lighten($black, 33.5%) !default; // #555
$gray-light:   lighten($black, 46.7%) !default; // #777
$gray-lighter: lighten($black, 93.5%) !default; // #eee
$white: #fff;

// Base color variables.
$link           : $primary-color;
$visited        : $link;
$selection-bg   : $gray-light;
$selection      : $primary-color;
