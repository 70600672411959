ul.tabs--primary {
	display: block;
	margin: 10px 0 20px;
	padding: 0;
	border-bottom: solid 1px darken(#fff, 60);
	zoom: 1;
	&:after {
		clear: both;
		display: table;
		content: "";
	}
	li {
		display: block;
		width: auto;
		height: 30px;
		padding: 0;
		float: left;
		margin-bottom: 0;
		-moz-border-radius-topright: 2px;
		-webkit-border-top-right-radius: 2px;
		border-top-right-radius: 2px;
		&:first-child a {
			border-width: 1px 1px 0 1px;
			-moz-border-radius-topleft: 2px;
			-webkit-border-top-left-radius: 2px;
			border-top-left-radius: 2px;
		}
		&:last-child a {
			-moz-border-radius-topright: 2px;
			-webkit-border-top-right-radius: 2px;
			border-top-right-radius: 2px;
		}
		a {
			display: block;
			text-decoration: none;
			width: auto;
			height: 30px;
			padding: 0px 20px;
			line-height: 30px;
			border: solid 1px darken(#fff, 60);
			border-width: 1px 1px 0 0;
			margin: 0;
			background: lighten(#282828, 5);
			font-size: 13px;
			color: darken(#fff, 40);
			&.active {
				background: #282828;
				height: 31px;
				position: relative;
				border-left-width: 1px;
				margin: 0 0 0 -1px;
				color: #fff;
				-moz-border-radius-topleft: 2px;
				-webkit-border-top-left-radius: 2px;
				border-top-left-radius: 2px;
				-moz-border-radius-topright: 2px;
				-webkit-border-top-right-radius: 2px;
				border-top-right-radius: 2px;
			}
		}
	}
}

ul.tabs-content { margin: 0; display: block; }
ul.tabs-content > li { display:none; }
ul.tabs-content > li.active { display: block; }

ul.tabs:before,
ul.tabs:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}
