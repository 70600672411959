#views-exposed-form-distributors-page {
  #edit-field-geofield-distance-wrapper {
    padding-top: 0;
    padding-right: 0;
  }
  .form-item-field-geofield-distance-distance {
    width: 60px;
  }
  .form-item-field-geofield-distance-unit {
    padding-top: 0;
  }
  .geofield-proximity-origin {
    max-width: 250px;
  }
  .views-exposed-widget {
    padding-top: 0;
    .btn {
      margin-top: 0;
    }
  }
}
