.header {
  height: 200px;
  background: $black url(../images/header-bg.jpg) no-repeat center top;
  &__logo {
    width: 255px;
    margin: 45px auto 0 auto;
    @media #{$normal} {
      margin: 45px 0 0 0;
    }
  }
}

.header--front {
  background: transparent;
}
